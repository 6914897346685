export const models = [
  {
    id: 1,
    name: "450L",
    image: [
      {
        id: 1,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cfoce-450l-galeria_0003_DSC_1435c-1780x1180.jpg",
        model: "450L",
        description: "CFORCE",
      },
      {
        id: 2,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cfoce-450l-galeria_0000_OSS09755-copiar-1780x1180.jpg",
        model: "450L",
        description: "CFORCE",
      },
      {
        id: 3,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cfoce-450l-galeria_0002_OSS09805-copiar-1780x1180.jpg",
        model: "450L",
        description: "CFORCE",
      },
      {
        id: 4,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cfoce-450l-galeria_0001_DSC_2118-1780x1180.jpg",
        model: "450L",
        description: "CFORCE",
      },
      {
        id: 5,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cfoce-450l-galeria_0004_OSS09844-1780x1180.jpg",
        model: "450L",
        description: "CFORCE",
      }
    ],
  },
  {
    id: 2,
    name: "450S",
    image: [
      {
        id: 1,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cforce-450s_2023_0002_DSC_1467.jpg",
        model: "450S",
        description: "CFORCE",
      },
      {
        id: 2,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cforce-450s_2023_0003_DSC_2118.jpg",
        model: "450S",
        description: "CFORCE",
      },
      {
        id: 3,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cforce-450s_2023_0004_DSC_2136.jpg",
        model: "450S",
        description: "CFORCE",
      },
      {
        id: 4,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cforce-450s_2023_0000_DSC_0454.jpg",
        model: "450S",
        description: "CFORCE",
      },
      {
        id: 5,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cforce-450s_2023_0001_DSC_1014.jpg",
        model: "450S",
        description: "CFORCE",
      },
    ],
  },
  {
    id: 3,
    name: "520L",
    image: [
      {
        id: 1,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cforce_520L_laranja_0000_DSC_0243-laranja-1780x1180.jpg",
        model: "520L",
        description: "CFORCE",
      },
      {
        id: 2,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cforce_520L_laranja_0001_DSC_1721-laranja-1780x1180.jpg",
        model: "520L",
        description: "CFORCE",
      },
      {
        id: 3,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cforce_520L_laranja_0002_DSC_1177-laranja-1780x1180.jpg",
        model: "520L",
        description: "CFORCE",
      },
      {
        id: 4,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cforce_520L_laranja_0003_DSC_0653-laranja-1780x1180.jpg",
        model: "520L",
        description: "CFORCE",
      },
      {
        id: 5,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cforce_520L_laranja_0004_DSC_0266-Laranja-1780x1180.jpg",
        model: "520L",
        description: "CFORCE",
      },
    ],
  },
  {
    id: 4,
    name: "625",
    image: [
      {
        id: 1,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29013.jpeg",
        model: "625",
        description: "CFORCE",
      },
      {
        id: 2,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29006.jpeg",
        model: "625",
        description: "CFORCE",
      },
      {
        id: 3,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29010.jpeg",
        model: "625",
        description: "CFORCE",
      },
      {
        id: 4,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29005.jpeg",
        model: "625",
        description: "CFORCE",
      },
      {
        id: 5,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29009.jpeg",
        model: "625",
        description: "CFORCE",
      },
      {
        id: 6,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29008.jpeg",
        model: "625",
        description: "CFORCE",
      },
      {
        id: 7,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29007.jpeg",
        model: "625",
        description: "CFORCE",
      },
      {
        id: 8,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29011.jpeg",
        model: "625",
        description: "CFORCE",
      },
      {
        id: 9,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29012.jpeg",
        model: "625",
        description: "CFORCE",
      },
    ],
  },
  {
    id: 5,
    name: "1000",
    image: [
      {
        id: 1,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29023.jpeg",
        model: "1000",
        description: "CFORCE",
      },
      {
        id: 2,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29020.jpeg",
        model: "1000",
        description: "CFORCE",
      },
      {
        id: 3,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29018.jpeg",
        model: "1000",
        description: "CFORCE",
      },
      {
        id: 4,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29022.jpeg",
        model: "1000",
        description: "CFORCE",
      },
      {
        id: 5,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29021.jpeg",
        model: "1000",
        description: "CFORCE",
      },
      {
        id: 6,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29024.jpeg",
        model: "1000",
        description: "CFORCE",
      },
      {
        id: 7,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29016.jpeg",
        model: "1000",
        description: "CFORCE",
      },
      {
        id: 8,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29017.jpeg",
        model: "1000",
        description: "CFORCE",
      },
      {
        id: 9,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29019.jpeg",
        model: "1000",
        description: "CFORCE",
      },
    ],
  },
  {
    id: 6,
    name: "110",
    image: [
      {
        id: 1,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/12/cforce-110-home.png",
        model: "110",
        description: "CFORCE",
      },
      {
        id: 2,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/12/galeria_0002_CFORCE-110_Baja-Blue-51-1780x1080.jpg",
        model: "110",
        description: "CFORCE",
      },
      {
        id: 3,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/12/galeria_0003_20220330_CFROCE-110%E5%A4%96%E6%99%AF5_%E6%8B%B7%E8%B4%9D-1780x1080.jpg",
        model: "110",
        description: "CFORCE",
      },
      {
        id: 4,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/12/galeria_0001_Family-riding-47-1780x1080.jpg",
        model: "110",
        description: "CFORCE",
      },
      {
        id: 5,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/12/galeria_0000_CFORCE-110-1-1780x1080.jpg",
        model: "110",
        description: "CFORCE",
      },
      {
        id: 6,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/12/MY2023_CFORCE-110_Rocket-Red_Right_CE-copiar-1245x830.png.webp",
        model: "110",
        description: "CFORCE",
      }
    ],
  },
  {
    id: 7,
    name: "800 TRAIL",
    image: [
      {
        id: 1,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/painel.jpg",
        model: "800 TRAIL",
        description: "ZFORCE",
      },
      {
        id: 2,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/azul-acao-1780x1180.jpg",
        model: "800 TRAIL",
        description: "ZFORCE",
      },
      {
        id: 3,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/zforce-800-trail-preto-com-vermelho-1780x1180.jpg",
        model: "800 TRAIL",
        description: "ZFORCE",
      },
    ],
  },
  {
    id: 8,
    name: "1000 SPORT R",
    image: [
      {
        id: 1,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/capa.jpg",
        model: "1000 sport r",
        description: "ZFORCE",
      },
      {
        id: 2,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/20220418_ZFORCE1000-SPORT-R-O%C2%A5%C2%A62-%C2%A2%C2%A6%C2%A6-1780x1180.jpg",
        model: "1000 sport r",
        description: "ZFORCE",
      },
      {
        id: 3,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/preto_acao-1780x1180.jpg",
        model: "1000 sport r",
        description: "ZFORCE",
      },
      {
        id: 4,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/Sem-Titulo-4-1780x1180.jpg",
        model: "1000 sport r",
        description: "ZFORCE",
      },
    ],
  },
  {
    id: 9,
    name: "600",
    image: [
      {
        id: 1,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29063.jpeg",
        model: "600",
        description: "UFORCE",
      },
      {
        id: 2,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29065.jpeg",
        model: "600",
        description: "UFORCE",
      },
      {
        id: 3,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29064.jpeg",
        model: "600",
        description: "UFORCE",
      },
      {
        id: 4,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29062.jpeg",
        model: "600",
        description: "UFORCE",
      },
      {
        id: 5,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29066.jpeg",
        model: "600",
        description: "UFORCE",
      },
      {
        id: 6,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29060.jpeg",
        model: "600",
        description: "UFORCE",
      },
      {
        id: 7,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29061.jpeg",
        model: "600",
        description: "UFORCE",
      },
    ],
  },
  {
    id: 10,
    name: "1000",
    image: [
      {
        id: 1,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29075.jpeg",
        model: "1000",
        description: "UFORCE",
      },
      {
        id: 2,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29072.jpeg",
        model: "1000",
        description: "UFORCE",
      },
      {
        id: 3,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29076.jpeg",
        model: "1000",
        description: "UFORCE",
      },
      {
        id: 4,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29074.jpeg",
        model: "1000",
        description: "UFORCE",
      },
      {
        id: 5,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29073.jpeg",
        model: "1000",
        description: "UFORCE",
      },
      {
        id: 6,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29069.jpeg",
        model: "1000",
        description: "UFORCE",
      },
      {
        id: 7,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/foto730_29070.jpeg",
        model: "1000",
        description: "UFORCE",
      },
    ],
  },
  {
    id: 11,
    name: "49",
    image: [
      {
        id: 1,
        img: "https://www.funmotors.com.br/upload/produtos/thumb/laminha-49-2022-47-interno-094850.png",
        model: "49",
        description: "LAMINHA",
      },
      {
        id: 2,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-49-2022-5-100056.jpeg",
        model: "49",
        description: "LAMINHA",
      },
      {
        id: 3,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-49-2022-58-100056.jpeg",
        model: "49",
        description: "LAMINHA",
      },
      {
        id: 4,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-49-2022-97-100056.jpeg",
        model: "49",
        description: "LAMINHA",
      },
      {
        id: 5,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-49-2022-97-100057.jpeg",
        model: "49",
        description: "LAMINHA",
      },
      {
        id: 6,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-49-2022-63-100057.jpeg",
        model: "49",
        description: "LAMINHA",
      },
      {
        id: 7,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-49-2022-46-100057.jpeg",
        model: "49",
        description: "LAMINHA",
      },
      {
        id: 8,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-49-2022-37-100058.jpeg",
        model: "49",
        description: "LAMINHA",
      },
      {
        id: 9,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-49-2022-9-100055.jpeg",
        model: "49",
        description: "LAMINHA",
      },
    ],
  },
  {
    id: 12,
    name: "60",
    image: [
      {
        id: 1,
        img: "https://www.funmotors.com.br/upload/produtos/thumb/laminha-60-59-interno-030648.png",
        model: "60",
        description: "LAMINHA",
      },
      {
        id: 2,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-60-72-031857.jpeg",
        model: "60",
        description: "LAMINHA",
      },
      {
        id: 3,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-60-29-031858.jpeg",
        model: "60",
        description: "LAMINHA",
      },
      {
        id: 4,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-60-28-031859.jpeg",
        model: "60",
        description: "LAMINHA",
      },
      {
        id: 5,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-60-98-031901.jpeg",
        model: "60",
        description: "LAMINHA",
      },
      {
        id: 6,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-60-27-031903.jpeg",
        model: "60",
        description: "LAMINHA",
      },
      {
        id: 7,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-60-28-031905.jpeg",
        model: "60",
        description: "LAMINHA",
      },
      {
        id: 8,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-60-17-031913.jpeg",
        model: "60",
        description: "LAMINHA",
      },
      {
        id: 9,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-60-39-031852.jpeg",
        model: "60",
        description: "LAMINHA",
      },
    ],
  },
  {
    id: 13,
    name: "100",
    image: [
      {
        id: 1,
        img: "https://www.funmotors.com.br/upload/produtos/thumb/laminha-100-13-interno-032704.png",
        model: "100",
        description: "LAMINHA",
      },
      {
        id: 2,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-100-31-033532.jpeg",
        model: "100",
        description: "LAMINHA",
      },
      {
        id: 3,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-100-42-033532.jpeg",
        model: "100",
        description: "LAMINHA",
      },
      {
        id: 4,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-100-92-033536.jpeg",
        model: "100",
        description: "LAMINHA",
      },
      {
        id: 5,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-100-7-033538.jpeg",
        model: "100",
        description: "LAMINHA",
      },
      {
        id: 6,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-100-10-033539.jpeg",
        model: "100",
        description: "LAMINHA",
      },
      {
        id: 7,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-100-44-033527.jpeg",
        model: "100",
        description: "LAMINHA",
      },
      {
        id: 8,
        img: "https://www.funmotors.com.br/upload/produtos/laminha-100-95-033525.jpeg",
        model: "100",
        description: "LAMINHA",
      },
    ],
  },
  {
    id: 14,
    name: "125",
    image: [
      {
        id: 1,
        img: "https://www.funmotors.com.br/upload/produtos/thumb/raptor-125-2-interno-034226.png",
        model: "125",
        description: "RAPTOR",
      },
      {
        id: 2,
        img: "https://www.funmotors.com.br/upload/produtos/raptor-125-94-034809.jpeg",
        model: "125",
        description: "RAPTOR",
      },
      {
        id: 3,
        img: "https://www.funmotors.com.br/upload/produtos/raptor-125-65-034825.jpeg",
        model: "125",
        description: "RAPTOR",
      },
      {
        id: 4,
        img: "https://www.funmotors.com.br/upload/produtos/raptor-125-94-034825.jpeg",
        model: "125",
        description: "RAPTOR",
      },
      {
        id: 5,
        img: "https://www.funmotors.com.br/upload/produtos/raptor-125-63-034826.jpeg",
        model: "125",
        description: "RAPTOR",
      },
      {
        id: 6,
        img: "https://www.funmotors.com.br/upload/produtos/raptor-125-77-034826.jpeg",
        model: "125",
        description: "RAPTOR",
      },
      {
        id: 7,
        img: "https://www.funmotors.com.br/upload/produtos/raptor-125-26-034835.jpeg",
        model: "125",
        description: "RAPTOR",
      },
      {
        id: 8,
        img: "https://www.funmotors.com.br/upload/produtos/raptor-125-37-034805.jpeg",
        model: "125",
        description: "RAPTOR",
      },
    ],
  },
  {
    id: 15,
    name: "140",
    image: [
      {
        id: 1,
        img: "https://www.funmotors.com.br/upload/produtos/thumb/raptor-140-54-interno-035747.png",
        model: "140",
        description: "RAPTOR",
      },
      {
        id: 2,
        img: "https://www.funmotors.com.br/upload/produtos/raptor-140-2-041033.jpeg",
        model: "140",
        description: "RAPTOR",
      },
      {
        id: 3,
        img: "https://www.funmotors.com.br/upload/produtos/raptor-140-13-041035.jpeg",
        model: "140",
        description: "RAPTOR",
      },
      {
        id: 4,
        img: "https://www.funmotors.com.br/upload/produtos/raptor-140-31-041036.jpeg",
        model: "140",
        description: "RAPTOR",
      },
      {
        id: 5,
        img: "https://www.funmotors.com.br/upload/produtos/raptor-140-76-041036.jpeg",
        model: "140",
        description: "RAPTOR",
      },
      {
        id: 6,
        img: "https://www.funmotors.com.br/upload/produtos/raptor-140-21-041038.jpeg",
        model: "140",
        description: "RAPTOR",
      },
      {
        id: 7,
        img: "https://www.funmotors.com.br/upload/produtos/raptor-140-16-041045.jpeg",
        model: "140",
        description: "RAPTOR",
      },
      {
        id: 8,
        img: "https://www.funmotors.com.br/upload/produtos/raptor-140-12-041029.jpeg",
        model: "140",
        description: "RAPTOR",
      },
    ],
  },
  {
    id: 16,
    name: "49",
    image: [
      {
        id: 1,
        img: "https://www.funmotors.com.br/upload/produtos/thumb/ligeirinho-49-88-interno-083033.png",
        model: "49",
        description: "LIGEIRINHO",
      },
      {
        id: 2,
        img: "https://www.funmotors.com.br/upload/produtos/ligeirinho-49-2021-71-020917.jpeg",
        model: "49",
        description: "LIGEIRINHO",
      },
      {
        id: 3,
        img: "https://www.funmotors.com.br/upload/produtos/ligeirinho-49-2021-83-020920.jpeg",
        model: "49",
        description: "LIGEIRINHO",
      },
      {
        id: 4,
        img: "https://www.funmotors.com.br/upload/produtos/ligeirinho-49-2021-42-020920.jpeg",
        model: "49",
        description: "LIGEIRINHO",
      },
      {
        id: 5,
        img: "https://www.funmotors.com.br/upload/produtos/ligeirinho-49-2021-26-020920.jpeg",
        model: "49",
        description: "LIGEIRINHO",
      },
      {
        id: 6,
        img: "https://www.funmotors.com.br/upload/produtos/ligeirinho-49-2021-11-020925.jpeg",
        model: "49",
        description: "LIGEIRINHO",
      },
      {
        id: 7,
        img: "https://www.funmotors.com.br/upload/produtos/ligeirinho-49-2021-31-020926.jpeg",
        model: "49",
        description: "LIGEIRINHO",
      },
      {
        id: 8,
        img: "https://www.funmotors.com.br/upload/produtos/ligeirinho-49-2021-7-020947.jpeg",
        model: "49",
        description: "LIGEIRINHO",
      },
    ],
  },
  {
    id: 17,
    name: "90",
    image: [
      {
        id: 1,
        img: "https://www.funmotors.com.br/upload/produtos/thumb/avalanche-90-51-interno-071222.png",
        model: "90",
        description: "AVALANCHE",
      },
      {
        id: 2,
        img: "https://www.funmotors.com.br/upload/cores/thumb/thumb-072730.jpg",
        model: "90",
        description: "AVALANCHE",
      },
    ],
  },
  {
    id: 18,
    name: "110",
    image: [
      {
        id: 1,
        img: "https://www.funmotors.com.br/upload/produtos/thumb/taurus-110-98-interno-012221.png",
        model: "110",
        description: "TAURUS",
      },
      {
        id: 2,
        img: "https://www.funmotors.com.br/upload/produtos/taurus-110-41-020420.jpeg",
        model: "110",
        description: "TAURUS",
      },
      {
        id: 3,
        img: "https://www.funmotors.com.br/upload/produtos/taurus-110-7-020421.jpeg",
        model: "110",
        description: "TAURUS",
      },
      {
        id: 4,
        img: "https://www.funmotors.com.br/upload/produtos/taurus-110-5-020418.jpeg",
        model: "110",
        description: "TAURUS",
      },
    ],
  },
  {
    id: 19,
    name: "125",
    image: [
      {
        id: 1,
        img: "https://www.funmotors.com.br/upload/produtos/thumb/alphacross-125-32-interno-111759.png",
        model: "125",
        description: "ALPHACROSS",
      },
      {
        id: 2,
        img: "https://www.funmotors.com.br/upload/produtos/alphacross-125-55-113853.png",
        model: "125",
        description: "ALPHACROSS",
      },
      {
        id: 3,
        img: "https://www.funmotors.com.br/upload/produtos/alphacross-125-70-113853.png",
        model: "125",
        description: "ALPHACROSS",
      },
      {
        id: 4,
        img: "https://www.funmotors.com.br/upload/produtos/alphacross-125-22-113853.png",
        model: "125",
        description: "ALPHACROSS",
      },
      {
        id: 5,
        img: "https://www.funmotors.com.br/upload/produtos/alphacross-125-35-113854.png",
        model: "125",
        description: "ALPHACROSS",
      },
      {
        id: 6,
        img: "https://www.funmotors.com.br/upload/produtos/alphacross-125-93-113855.png",
        model: "125",
        description: "ALPHACROSS",
      },
      {
        id: 7,
        img: "https://www.funmotors.com.br/upload/produtos/alphacross-125-2-113855.png",
        model: "125",
        description: "ALPHACROSS",
      },
      {
        id: 8,
        img: "https://www.funmotors.com.br/upload/produtos/alphacross-125-16-113856.png",
        model: "125",
        description: "ALPHACROSS",
      },
    ],
  },
  {
    id: 19,
    name: "200",
    image: [
      {
        id: 1,
        img: "https://www.funmotors.com.br/upload/produtos/thumb/farmer-200-51-interno-041731.png",
        model: "200",
        description: "FARMER",
      },
      {
        id: 2,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-200-35-043017.jpeg",
        model: "200",
        description: "FARMER",
      },
      {
        id: 3,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-200-75-043017.jpeg",
        model: "200",
        description: "FARMER",
      },
      {
        id: 4,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-200-32-043021.jpeg",
        model: "200",
        description: "FARMER",
      },
      {
        id: 5,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-200-77-043023.jpeg",
        model: "200",
        description: "FARMER",
      },
      {
        id: 6,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-200-44-043023.jpeg",
        model: "200",
        description: "FARMER",
      },
      {
        id: 7,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-200-99-043030.jpeg",
        model: "200",
        description: "FARMER",
      },
      {
        id: 8,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-200-69-084936.jpeg",
        model: "200",
        description: "FARMER",
      },
    ],
  },
  {
    id: 20,
    name: "300",
    image: [
      {
        id: 1,
        img: "https://www.funmotors.com.br/upload/produtos/thumb/farmer-300-23-interno-082829.png",
        model: "300",
        description: "FARMER",
      },
      {
        id: 2,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-300-67-024927.png",
        model: "300",
        description: "FARMER",
      },
      {
        id: 3,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-300-87-024927.png",
        model: "300",
        description: "FARMER",
      },
      {
        id: 4,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-300-17-024928.png",
        model: "300",
        description: "FARMER",
      },
      {
        id: 5,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-300-72-024928.png",
        model: "300",
        description: "FARMER",
      },
      {
        id: 6,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-300-72-024928.png",
        model: "300",
        description: "FARMER",
      },
      {
        id: 7,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-300-78-024929.png",
        model: "300",
        description: "FARMER",
      },
      {
        id: 8,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-300-67-024930.png",
        model: "300",
        description: "FARMER",
      },
      {
        id: 9,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-300-76-041013.png",
        model: "300",
        description: "FARMER",
      },
      {
        id: 10,
        img: "https://www.funmotors.com.br/upload/produtos/farmer-300-15-024927.png",
        model: "300",
        description: "FARMER",
      },
    ],
  },
  {
    id: 21,
    name: "125EX",
    image: [
      {
        id: 1,
        img: "https://www.funmotors.com.br/upload/produtos/thumb/alphacross-125-ex-34-interno-101117.png",
        model: "125ex",
        description: "ALPHACROSS",
      },
      {
        id: 2,
        img: "https://www.funmotors.com.br/upload/produtos/alphacross-125-ex-82-102857.jpeg",
        model: "125ex",
        description: "ALPHACROSS",
      },
      {
        id: 3,
        img: "https://www.funmotors.com.br/upload/produtos/alphacross-125-ex-24-102857.jpeg",
        model: "125ex",
        description: "ALPHACROSS",
      },
      {
        id: 4,
        img: "https://www.funmotors.com.br/upload/produtos/alphacross-125-ex-75-102858.jpeg",
        model: "125ex",
        description: "ALPHACROSS",
      },
      {
        id: 5,
        img: "https://www.funmotors.com.br/upload/produtos/alphacross-125-ex-49-102857.jpeg",
        model: "125ex",
        description: "ALPHACROSS",
      },
    ],
  },
  {
    id: 22,
    name: "110",
    image: [
      {
        id: 1,
        img: "https://www.funmotors.com.br/upload/produtos/thumb/temporario-92-interno-084426.png",
        model: "110",
        description: "RHINO",
      },
      {
        id: 2,
        img: "https://www.funmotors.com.br/upload/produtos/temporario-26-091140.jpeg",
        model: "110",
        description: "RHINO",
      },
      {
        id: 3,
        img: "https://www.funmotors.com.br/upload/produtos/temporario-58-091141.jpeg",
        model: "110",
        description: "RHINO",
      },
      {
        id: 4,
        img: "https://www.funmotors.com.br/upload/produtos/temporario-41-091141.jpeg",
        model: "110",
        description: "RHINO",
      },
      {
        id: 5,
        img: "https://www.funmotors.com.br/upload/produtos/temporario-23-091140.jpeg",
        model: "110",
        description: "RHINO",
      },
    ],
  },
  {
    id: 23,
    name: "1000XL",
    image: [
      {
        id: 1,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/WALLPAPER_0003_20220119_UFORCE1000-action-1780x1080.jpg",
        model: "1000xl",
        description: "UFORCE",
      },
      {
        id: 2,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/WALLPAPER_0002_CFMOTO_ATV_UFORCE-1000-XL-2-copiar-1780x1080.jpg",
        model: "1000xl",
        description: "UFORCE",
      },
      {
        id: 3,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/WALLPAPER_0001_CFMOTO_ATV_UFORCE-1000-XL-3-1780x1080.jpg",
        model: "1000xl",
        description: "UFORCE",
      },
      {
        id: 4,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/WALLPAPER_0000_CFMOTO_ATV_UFORCE-1000-XL-4-1780x1080.jpg",
        model: "1000xl",
        description: "UFORCE",
      },
      {
        id: 5,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/home_uforce_1000.png",
        model: "1000xl",
        description: "UFORCE",
      },
    ],
  },
  {
    id: 24,
    name: "EV110",
    image: [
      {
        id: 1,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2023/01/GALERIA-CFORCE-EV110_0000_CFORCE-EV-110_Polar-White-53-1780x1080.jpg",
        model: "ev110",
        description: "CFORCE",
      },
      {
        id: 2,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2023/01/GALERIA-CFORCE-EV110_0003_CFORCE-EV-110_Polar-white-1780x1080.jpg",
        model: "ev110",
        description: "CFORCE",
      },
      {
        id: 3,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2023/01/GALERIA-CFORCE-EV110_0002_CFORCE-EV-110_Polar-White-24-1780x1080.jpg",
        model: "ev110",
        description: "CFORCE",
      },
      {
        id: 4,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2023/01/GALERIA-CFORCE-EV110_0001_CFORCE-EV-110_Polar-White-36-1780x1080.jpg",
        model: "ev110",
        description: "CFORCE",
      },
    ],
  }
];

export const modelsOverflow = [
  {
    id: 1,
    name: "CFORCE",
    image: [
      {
        id: 10,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/12/cforce-110-home.png",
        model: "110",
        name: "110",
        description: "CFORCE",
        category: "CFORCE",
        type: "cf",
      },
      {
        id: 11,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/CFORCE-450-L-LARANJA-LATERAL-site.png",
        model: "450L",
        name: "450L",
        description: "CFORCE",
        category: "CFORCE",
        type: "cf",
      },
      {
        id: 12,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cforce-450s_2022-azul_principal-1218x830-1.png",
        model: "450S",
        name: "450S",
        description: "CFORCE",
        category: "CFORCE",
        type: "cf",
      },
      {
        id: 13,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/08/cforce_520L_2023_home_red-1.png",
        model: "520L",
        name: "520L",
        description: "CFORCE",
        category: "CFORCE",
        type: "cf",
      },
      {
        id: 14,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/original_model_1086.jpeg",
        model: "625",
        name: "625",
        description: "CFORCE",
        category: "CFORCE",
        type: "cf",
      },
      {
        id: 15,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/original_model_1087.jpeg",
        model: "1000",
        name: "1000",
        description: "CFORCE",
        category: "CFORCE",
        type: "cf",
      },
      {
        id: 16,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2023/01/CFORCE-EV-110_HOME.png",
        model: "EV110",
        name: "EV110",
        description: "CFORCE",
        category: "CFORCE",
        type: "cf",
      },
    ],
  },
  {
    id: 2,
    name: "UFORCE",
    image: [
      {
        id: 21,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/original_model_1092.jpeg",
        model: "1000",
        name: "1000",
        description: "UFORCE",
        category: "UFORCE",
        type: "cf",
      },
      {
        id: 22,
        img: "https://api.dealersites.com.br/images/novos/cfmoto/models/original_model_1091.jpeg",
        model: "600",
        name: "600",
        description: "UFORCE",
        category: "UFORCE",
        type: "cf",
      },
      {
        id: 23,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/home_uforce_1000.png",
        model: "1000XL",
        name: "1000XL",
        description: "UFORCE",
        category: "UFORCE",
        type: "cf",
      },
    ],
  },
  {
    id: 3,
    name: "ZFORCE",
    image: [
      // {
      //   id: 31,
      //   img: "https://api.dealersites.com.br/images/novos/cfmoto/models/original_model_1088.jpeg",
      //   model: "550",
      //   description: "ZFORCE",
      //   category:"ZFORCE",
      //   type: "cf",
      // },
      {
        id: 32,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/z800-home.png",
        model: "800TRAIL",
        name: "800 TRAIL",
        description: "ZFORCE",
        category: "ZFORCE",
        type: "cf",
      },
      {
        id: 33,
        img: "https://www.cfmoto.com.br/wp-content/uploads/2022/09/Z1000-SPORT-R-HOME-500x341.png",
        model: "1000SPORTR",
        name: "1000 SPORT R",
        description: "ZFORCE",
        category: "ZFORCE",
        type: "cf",
      }
    ],
  },
];

export const modelsOverflowFun = [
  {
    id: 1,
    name: "MINI-MOTOS",
    image: [
      {
        id: 11,
        img: "https://www.funmotors.com.br/wp-content/uploads/2023/02/laminha-49-2022-47-interno-094850.png",
        model: "49",
        name: "49",
        description: "LAMINHA",
        category: "MINI-MOTOS",
        type: "fun",
      },
      {
        id: 12,
        img: "https://www.funmotors.com.br/wp-content/uploads/2023/02/laminha-60-59-interno-030648.png",
        model: "60",
        name: "60",
        description: "LAMINHA",
        category: "MINI-MOTOS",
        type: "fun",
      },
      {
        id: 13,
        img: "https://www.funmotors.com.br/wp-content/uploads/2023/02/laminha-100-13-interno-032704.png",
        model: "100",
        name: "100",
        description: "LAMINHA",
        category: "MINI-MOTOS",
        type: "fun",
      },
      {
        id: 14,
        img: "https://www.funmotors.com.br/wp-content/uploads/2023/02/raptor-125-2-interno-034226.png",
        model: "125",
        name: "125",
        description: "RAPTOR",
        category: "MINI-MOTOS",
        type: "fun",
      },
      {
        id: 15,
        img: "https://www.funmotors.com.br/wp-content/uploads/2023/03/raptor-140-54-interno-035747.png",
        model: "140",
        name: "140",
        description: "RAPTOR",
        category: "MINI-MOTOS",
        type: "fun",
      },
    ],
  },
  {
    id: 2,
    name: "MINI-QUADRICICLOS",
    type: "fun",
    image: [
      {
        id: 21,
        img: "https://www.funmotors.com.br/wp-content/uploads/2023/02/ligeirinho-49-88-interno-083033.png",
        model: "49",
        name: "49",
        description: "LIGEIRINHO",
        category: "MINI-QUADRICICLOS",
        type: "fun",
      },
      {
        id: 22,
        img: "https://www.funmotors.com.br/wp-content/uploads/2023/02/avalanche-90-51-interno-071222.png",
        model: "90",
        name: "90",
        description: "AVALANCHE",
        category: "MINI-QUADRICICLOS",
        type: "fun",
      },
      {
        id: 23,
        img: "https://www.funmotors.com.br/wp-content/uploads/2023/02/taurus-110-98-interno-012221.png",
        model: "110",
        name: "110",
        description: "TAURUS",
        category: "MINI-QUADRICICLOS",
        type: "fun",
      },
      {
        id: 24,
        img: "https://www.funmotors.com.br/wp-content/uploads/2023/02/temporario-92-interno-084426.png",
        model: "110",
        name: "110",
        description: "RHINO",
        category: "MINI-QUADRICICLOS",
        type: "fun",
      },
    ],
  },
  {
    id: 3,
    name: "QUADRICICLOS",
    type: "fun",
    image: [
      {
        id: 31,
        img: "https://www.funmotors.com.br/wp-content/uploads/2023/02/alphacross-125-32-interno-111759.png",
        model: "125",
        name: "125",
        description: "ALPHACROSS",
        category: "QUADRICICLOS",
        type: "fun",
      },
      {
        id: 33,
        img: "https://www.funmotors.com.br/wp-content/uploads/2023/02/alphacross-125-ex-34-interno-101117.png",
        model: "125EX",
        name: "125EX",
        description: "ALPHACROSS",
        category: "QUADRICICLOS",
        type: "fun",
      },
      {
        id: 33,
        img: "https://www.funmotors.com.br/wp-content/uploads/2023/02/farmer-200-51-interno-041731.png",
        model: "200",
        name: "200",
        description: "FARMER",
        category: "QUADRICICLOS",
        type: "fun",
      },
      {
        id: 34,
        img: "https://www.funmotors.com.br/wp-content/uploads/2023/02/farmer-300-23-interno-082829.png",
        model: "300",
        name: "300",
        description: "FARMER",
        category: "QUADRICICLOS",
        type: "fun",
      },
    ],
  },
];
